import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ShopService} from 'src/app/shared/api/shop.service';
import {Layout} from '../../components/products-view/products-view.component';
import {ProductService} from '../../services/products.service';
import {SharedService} from '../../../../shared/services/shared.service';
import {URL_SERVICES} from '../../../../config/config';
import {CompanyService} from '../../../../shared/services/company.service';

@Component({
    selector: 'app-page-search',
    templateUrl: './page-search.component.html',
    styleUrls: ['./page-search.component.scss']
})
export class PageSearchComponent implements OnInit {
    productSearch: any = [];
    layout: Layout = 'grid';
    grid: 'grid-3-sidebar' | 'grid-4-full' | 'grid-5-full' = 'grid-5-full';
    offcanvas: 'always' | 'mobile' = 'mobile';
    order: string = 'a-z';
    // pageActual: number = 1;
    pageSize: number = 10;
    term: string;
    pagination = {
        last_page: 1,
        current_page: 1,
        path: '',
        per_page: 1,
        to: 1,
        total: 1
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private _shopService: ShopService,
        public sharedService: SharedService,
        public _companyService: CompanyService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            const {term} = params;
            if (term.trim() && term.trim().length > 0) {
                this.term = term.trim();
                this.getProductsByTerm(term.trim());
            }
        })
    }

    ngOnInit(): void {
    }

    // sorting type ASC / DESC / A-Z / Z-A etc.
    public onChangeSorting(val) {
        this.sharedService.pageActualSearch = 1;
        const url = URL_SERVICES + 'get-products-search/' + this.term + '/' + this._companyService.user.id + '/20000/' + val + `?page=${this.sharedService.pageActualPromotions}`

        this._shopService.getSearchTermUrl(url).then((resp) => {
            this.productSearch = resp;

            this.pagination = this.productSearch.meta ? this.productSearch.meta : {last_page: 1, current_page: 1, path: ''}
        });
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async getProductsByTerm(term: string) {
        const products: any = await this._shopService.getSearchTerm(term);
        // this.productSearch = await this._shopService.getProductsLista();
        products.data.map((product) => {
            product.priceWithIva = product.price + (product.price * product.iva);
            return product;
        });
        this.productSearch = products;

        this.pagination = this.productSearch.meta ? this.productSearch.meta : {last_page: 1, current_page: 1, path: ''}
    }

    newPage(event) {
        this.sharedService.pageActualSearch = event;
        const url = this.pagination.path + `?page=${event}`
        this._shopService.getSearchTermUrl(url, null).then((resp: any) => {
            resp.data.map((product) => {
                product.priceWithIva = product.price + (product.price * product.iva);
                return product;
            });
            this.productSearch = resp;
            this.pagination = this.productSearch.meta ? this.productSearch.meta : {last_page: 1, current_page: 1, path: ''}
        });
    }
}
